<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="pt-4">
                <b-row>
                    <b-col sm="12" md="8">
                        <b-card no-body>
                            <b-card-header>
                                <h1 v-if="hackathon_id !== null" class="mb-0">
                                    Edit Hackathon
                                </h1>
                                <h1 v-else class="mb-0">Create Hackathon</h1>
                            </b-card-header>

                            <b-card-body>
                                <validation-observer
                                    v-slot="{ handleSubmit }"
                                    ref="formValidator"
                                >
                                    <b-form
                                        role="form"
                                        @submit.prevent="
                                            handleSubmit(submit_form)
                                        "
                                    >
                                        <base-input
                                            :pill="true"
                                            label="Hackathon Name*"
                                            class="mb-3"
                                            name="Hackathon Name"
                                            :rules="{ required: true }"
                                            placeholder="Enter your Idea's name"
                                            v-model="form.hackathon_name"
                                            :disabled="$apollo.loading"
                                        >
                                        </base-input>

                                        <base-input
                                            :pill="true"
                                            label="Hackathon short description* "
                                            class="mb-3"
                                            name="Hackathon Description"
                                            placeholder="A one liner that describes your idea"
                                            v-model="form.hackathon_short_desc"
                                            :disabled="$apollo.loading"
                                            :rules="{
                                                required: true,
                                            }"
                                        >
                                        </base-input>

                                        <base-input
                                            label="Organization"
                                            :rules="{ required: true }"
                                            :pill="true"
                                            name="Organization"
                                            :disabled="$apollo.loading"
                                        >
                                            <el-select
                                                class="form"
                                                v-model="
                                                    form.organization.value
                                                "
                                                :filterable="true"
                                            >
                                                <el-option
                                                    v-for="option in form
                                                        .organization.options"
                                                    :key="option.id"
                                                    :label="option.name"
                                                    :value="option.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </base-input>

                                        <html-editor
                                            :pill="true"
                                            label="Hackathon detail description"
                                            class="mb-3"
                                            name="Detailed Description"
                                            v-model="form.hackathon_long_desc"
                                            :disabled="$apollo.loading"
                                        >
                                        </html-editor>

                                        <base-input
                                            label="Who can participate*"
                                            :rules="{ required: true }"
                                            :pill="true"
                                            name="Particpants*"
                                            :disabled="$apollo.loading"
                                        >
                                            <el-select
                                                class="form"
                                                v-model="
                                                    form.input_dropdown
                                                        .selection_id
                                                "
                                                :rules="{ required: true }"
                                                :default-first-option="true"
                                                :filterable="true"
                                            >
                                                <el-option
                                                    v-for="option in form
                                                        .input_dropdown.options"
                                                    :key="option.id"
                                                    :label="option.friendlyName"
                                                    :value="option.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </base-input>

                                        <base-input
                                            label="What is the maximum team size"
                                            :rules="{
                                                required: true,
                                                numeric: true,
                                                max_value: 10,
                                            }"
                                            :pill="true"
                                            name="Max team size"
                                            :disabled="$apollo.loading"
                                            v-model="form.max_team_size"
                                        >
                                        </base-input>

                                        <b-row>
                                            <b-col sm="6">
                                                <base-input
                                                    :pill="true"
                                                    type="datetime-local"
                                                    id="start-datetime"
                                                    label="Start date and time"
                                                    v-model="form.start_date"
                                                    :rules="{ required: true }"
                                                    :disabled="$apollo.loading"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6">
                                                <base-input
                                                    :pill="true"
                                                    type="datetime-local"
                                                    id="end-datetime"
                                                    label="End date and time"
                                                    v-model="form.end_date"
                                                    :rules="{ required: true }"
                                                    :disabled="$apollo.loading"
                                                ></base-input>
                                            </b-col>
                                        </b-row>

                                        <div class="text-right">
                                            <base-button
                                                type="danger"
                                                :pill="true"
                                                class="my-4 btn-success btn-right"
                                                v-b-modal.delete_modal
                                                v-if="hackathon_id !== null"
                                                :disabled="form.loading.delete"
                                                :loading="form.loading.delete"
                                            >
                                                Delete
                                            </base-button>
                                            <base-button
                                                type="primary"
                                                :pill="true"
                                                native-type="submit"
                                                class="my-4 btn-success btn-right"
                                                :disabled="form.loading.save"
                                                :loading="form.loading.save"
                                            >
                                                Save
                                            </base-button>
                                        </div>
                                    </b-form>
                                </validation-observer>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>

                <b-modal
                    id="delete_modal"
                    title="Confirm"
                    size="sm"
                    no-close-on-backdrop
                    no-close-on-esc
                    hide-footer
                >
                    <div class="text-center">
                        <h1>Are you sure?</h1>
                        <p>
                            You are about to delete the hackathon:<br />
                            <strong>"{{ this.form.hackathon_name }}"</strong>
                        </p>
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="delete_hackathon"
                            >Delete
                        </base-button>
                        <base-button
                            type="primary"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('delete_modal')"
                            >Cancel
                        </base-button>
                    </div>
                </b-modal>
            </b-container>
        </div>

        <b-container class="mt--6">
            <b-row>
                <b-col sm="12">
                    <div></div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option } from "element-ui";
import {
    GET_HACKATHON_HACKATHON,
    GET_ORGANIZATION_ASSOCIATED,
    GET_ALL_HACKATHON_ALLOWED_PARTICIPANT_OPTIONS,
} from "@/graphql/queries";
import {
    CREATE_HACKATHON,
    UPDATE_HACKATHON,
    DELETE_HACKATHON,
} from "@/graphql/mutations";

export default {
    name: "CrudHackathon",
    components: {
        BaseInput,
        HtmlEditor,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    emits: ["hackathon-created"],
    apollo: {
        get_hackathon_hackathon: {
            query: GET_HACKATHON_HACKATHON,
            result(data) {
                this.form.hackathon_name = data.data.hackathonHackathon.name;
                this.form.hackathon_short_desc =
                    data.data.hackathonHackathon.description;
                this.form.hackathon_long_desc =
                    data.data.hackathonHackathon.detailDescription;
                this.form.start_date =
                    data.data.hackathonHackathon.startDatetime.substring(0, 16);
                this.form.end_date =
                    data.data.hackathonHackathon.endDatetime.substring(0, 16);
                this.form.max_team_size =
                    data.data.hackathonHackathon.maxTeamSize;
                this.form.input_dropdown.selection_id =
                    data.data.hackathonHackathon.allowedParticipants.id;
                if (data.data.hackathonHackathon.organization) {
                    this.form.organization.value =
                        data.data.hackathonHackathon.organization.id;
                }
            },
            update(data) {
                this.apollo_data.get_hackathon_hackathon = data;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    id: this.hackathon_id,
                };
            },
            skip: true,
        },

        allHackathonAllowedParticipantOptions: {
            query: GET_ALL_HACKATHON_ALLOWED_PARTICIPANT_OPTIONS,
            result(data) {
                this.form.input_dropdown.options = [];
                data.data.allHackathonAllowedParticipantOptions.edges.forEach(
                    (i) => {
                        this.form.input_dropdown.options.push(i.node);
                    }
                );
                if (!this.hackathon_id) {
                    this.form.input_dropdown.selection_id =
                        data.data.allHackathonAllowedParticipantOptions.edges[0].node.id;
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
        },

        get_all_associated_organizations: {
            query: GET_ORGANIZATION_ASSOCIATED,
            result(res) {
                this.form.organization.options = [];
                let organizations = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allOrganization
                    )
                );
                if (organizations.length > 0) {
                    organizations.forEach((el) => {
                        this.form.organization.options.push({
                            id: el.id,
                            name: el.name,
                        });
                    });

                    // this.form.organization.value = {
                    //     id: organizations[0].id,
                    //     name: organizations[0].name,
                    // };
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },

            update(data) {
                this.apollo_data.get_all_associated_organizations = data;
            },
        },
    },
    props: {},
    data() {
        return {
            apollo_data: {
                get_hackathon_hackathon: {},
                get_all_associated_organizations: {},
            },
            hackathon_id: null,
            form: {
                hackathon_name: null,
                hackathon_short_desc: null,
                hackathon_long_desc: "",
                start_date: null,
                end_date: null,
                max_team_size: 5,
                input_dropdown: {
                    selection_id: null,
                    options: [],
                    max_team_options: [],
                },
                loading: {
                    save: false,
                    delete: false,
                },
                organization: {
                    value: null,
                    options: [
                        {
                            id: "ExampleID",
                            name: "Example Name",
                        },
                    ],
                },
            },
        };
    },
    methods: {
        submit_form() {
            const hackathon = {
                name: this.form.hackathon_name,
                hackathon_short_desc: this.form.hackathon_short_desc,
                hackathon_long_desc: this.form.hackathon_long_desc,
                allowed_participants_id: this.form.input_dropdown.selection_id,
                start_date: this.form.start_date,
                end_date: this.form.end_date,
                max_team_size: this.form.max_team_size,
            };

            if (this.hackathon_id) {
                this.update_hackathon(hackathon);
            } else {
                this.create_hackathon(hackathon);
            }
        },

        //Apollo Methods

        enable_get_hackathon_hackathon() {
            this.$apollo.queries.get_hackathon_hackathon.setOptions({
                fetchPolicy: "network-only",
            });

            if (!this.$apollo.queries.get_hackathon_hackathon.skip) {
                this.$apollo.queries.get_hackathon_hackathon.refetch();
            } else {
                this.$apollo.queries.get_hackathon_hackathon.skip = false;
                this.$apollo.queries.get_hackathon_hackathon.start();
            }
        },

        get_hackathon_id() {
            if (this.$route.params.hackathon_id) {
                this.hackathon_id = this.$route.params.hackathon_id;
                this.enable_get_hackathon_hackathon();
            } else {
                this.hackathon_id = null;
                // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
            }
        },
        update_hackathon(hackathon) {
            this.form.loading.save = true;
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON,
                    variables: {
                        id: this.hackathon_id,
                        name: hackathon.name,
                        description: hackathon.hackathon_short_desc,
                        detailDescription: hackathon.hackathon_long_desc,
                        allowedParticipants: hackathon.allowed_participants_id,
                        maxTeamSize: hackathon.max_team_size,
                        startDatetime: hackathon.start_date,
                        endDatetime: hackathon.end_date,
                        organization: this.form.organization.value,
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        this.$emit("updated");
                    }
                    this.form.loading.save = false;
                })
                .catch((res) => {
                    this.form.loading.save = false;
                });
        },
        create_hackathon(hackathon) {
            this.form.loading.save = true;
            this.$apollo
                .mutate({
                    mutation: CREATE_HACKATHON,
                    variables: {
                        creator_id: this.$store.getters.getUserIdB64,
                        name: hackathon.name,
                        description: hackathon.hackathon_short_desc,
                        detailDescription: hackathon.hackathon_long_desc,
                        allowedParticipants: hackathon.allowed_participants_id,
                        maxTeamSize: hackathon.max_team_size,
                        startDatetime: hackathon.start_date,
                        endDatetime: hackathon.end_date,
                        organization: this.form.organization.value,
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        this.$emit("created");
                        this.hackathon_id =
                            res.data.hackathonCreateHackathon.hackathon.id;
                    }
                    this.form.loading.save = false;
                })
                .catch((res) => {
                    this.form.loading.save = false;
                });
        },
        delete_hackathon() {
            this.form.loading.delete = true;
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON,
                    variables: {
                        id: this.hackathon_id,
                    },
                })
                .then((res) => {
                    if ("data" in res) {
                        this.$emit("deleted");
                    }
                    this.form.loading.delete = false;
                    this.$bvModal.hide("delete_modal");
                    setTimeout(() => {
                        this.$router.push("manage");
                    }, 1000);
                })
                .catch((res) => {
                    this.form.loading.delete = false;
                });
        },
    },
    created() {
        this.get_hackathon_id();
    },
};
</script>
<style>

.el-input__inner {
    border-radius: 10rem;
}
</style>
